@mixin mediaBigDesktop {
  @media (width > 2566px) {
    @content;
  }
}

@mixin mediaDesktop {
  @media (width <= 2000px) {
    @content;
  }
}

@mixin mediaLaptop {
  @media (width <= 1500px) {
    @content;
  }
}

@mixin mediaTablet {
  @media (width <= 1250px) {
    @content;
  }
}

@mixin mediaMobile {
  @media (width <= 630px) {
    @content;
  }
}

@mixin media($width) {
  @media (width <= $width) {
    @content;
  }
}
